import React from 'react';

export default function AppLayout({ children, noPadding }: {children: React.ReactNode, noPadding?: boolean}) {
	const h = window.innerHeight;
	return (
		<>
			<div
				className=" md:rounded-xl pb-6 px-4 sm:px-8 bg-neutral-90 relative shadow"

				style={{minHeight: `${h - 130}px`}}
			>
				{children}
			</div>
		</>
	);
}