import React from 'react';

export default function TitledList(props: {title : string, children : any}) {
	const {title, children} = props;
	const modifyChildren = (child : any) => {
		if (child === '') return;
		return (
			<li className={'px-2 py-2 flex justify-between relative'}>
				{child}
			</li>
		);
	};

	return (
		<ul className={'bg-neutral-80 border border-neutral-70 rounded-lg text-sm'}>
			{/*<li className={'border-b-2 px-2 py-1 border-neutral-70 font-semibold'}>{title}</li>*/}
			{React.Children.map(children, (child) => modifyChildren(child))}
		</ul>
	);
}