import React, {useEffect, useState} from 'react';
import { MagnifyingGlassIcon } from '@icons';
import {useDispatch, useSelector} from 'react-redux';
import { setSearchQuery, clearSearchQuery } from 'store/slices/searchSlice';
import {XMarkIcon} from '@icons';
import {RootState} from 'store';
export default function SearchBar() {
	const [isHidden, setIsHidden] = useState(false);

	const query = useSelector((state: RootState) => state.search.query);

	const dispatch = useDispatch();
	const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setSearchQuery(event.target.value));
	};

	const clearSearch = () => {
		dispatch(clearSearchQuery());
	};

	const path = window.location.pathname;
	let app = path.split('/')[1].toLowerCase();
	app = app.charAt(0).toUpperCase() + app.slice(1);


	return (
		<div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 ">
			<div className="relative flex flex-1 ">
				<label htmlFor="search-field" className="sr-only">
          Search
				</label>
				{!path.split('/')[2] && path.split('/')[1] !== 'home' ?
					<>
						<MagnifyingGlassIcon
							className="pointer-events-none absolute inset-y-0 left-2 h-full w-5 text-neutral-50"
							aria-hidden="true"
						/>
						<input
							id="search-field"
							className={`${query.length > 0 ? 'lg:w-full' : 'lg:focus:w-full'} transition-width duration-200 block h-full shadow w-full lg:w-2/5 rounded-md py-0 pl-8 pr-0 text-neutral-5 bg-neutral-90 placeholder:text-neutral-40 focus:ring-0 sm:text-sm`}
							placeholder={`Search ${app}`}
							autoComplete="off"
							type="search"
							value={query}
							onChange={handleQueryChange}
						/>
						{query.length > 0 ?
							<button className="absolute right-4 h-full w-5 text-gray-400" onClick={clearSearch}>
								<XMarkIcon
									aria-hidden="true"
								/>
							</button> : null}
					</> : null}
			</div>
		</div>
	);
}