import React, {useEffect, useRef} from 'react';
import { useCalendarState } from '@react-stately/calendar';
import { useCalendar } from '@react-aria/calendar';
import { useLocale } from '@react-aria/i18n';
import { createCalendar } from '@internationalized/date';
import { CalendarGrid, CalendarHeader } from './components';
import { useDebounce } from '@modules/common/hooks';
export default function Calendar({ updateObservationDate, defaultValue, ...props }: { updateObservationDate: (updatedObservationDate: any) => void, defaultValue: any }) {
	const { locale } = useLocale();
	const state = useCalendarState({
		...props,
		locale,
		createCalendar
	});

	const ref = useRef<HTMLDivElement | null>(null);

	const { calendarProps, prevButtonProps, nextButtonProps } = useCalendar(
		props,
		state,
	);

	const { value, setValue } = state;

	useEffect(() => {
		setValue(defaultValue);
	}, []);

	useEffect(() => {
		if (value && value.month && value.day && value.year && !(value.month === defaultValue.month && value.day === defaultValue.day && value.year === defaultValue.year)) {
			const month = String(value.month).padStart(2, '0');
			const day = String(value.day).padStart(2, '0');
			const year = value.year;
			updateObservationDate(`${month}-${day}-${year}`);
		}
	}, [value]);

	return (
		<div {...calendarProps} ref={ref} className="inline-block  w-full px-4 py-3">
			<CalendarHeader
				state={state}
				calendarProps={calendarProps}
				prevButtonProps={prevButtonProps}
				nextButtonProps={nextButtonProps}
			/>

			<div className="flex gap-8">
				<CalendarGrid state={state} />
			</div>
		</div>
	);
}
