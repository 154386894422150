import axios, {AxiosInstance, HeadersDefaults} from 'axios';
import { authEndpoints } from 'services/api';
import { ConnectError } from 'errors';
import { Tokens } from '@types';

type CustomHeaders = Record<string, string> & HeadersDefaults;

const axiosInstance: AxiosInstance = axios.create({
	timeout: 20000,
	headers: {}
});

const refreshAccessToken = async (): Promise<Tokens> => {
	const refreshToken = localStorage.getItem('refresh');
	if (!refreshToken) throw new ConnectError('AuthenticationError', 'Refresh token not found. Please login again', 'localStorage empty');

	return axiosInstance.post(authEndpoints.refresh, {
		refresh: refreshToken
	}).then(function (response) {
		setAuthorizationHeader(response.data.access);
		return response.data;
	}).catch(function (error) {
		throw error;
	});
};

const setAuthorizationHeader = (accessToken: string) => {
	// Use type assertion to suppress TypeScript error
	(axiosInstance.defaults.headers as CustomHeaders)['Authorization'] = `Bearer ${accessToken}`;
};

export { axiosInstance, setAuthorizationHeader, refreshAccessToken };
