import React from 'react';
import { useSelector } from 'react-redux';

interface TableRowPropsType {
  options?: any,
  entry: any,
  onRowClick?: any,
  columns: any
}
const TableRow = (props : TableRowPropsType) => {

	const pinnedCard = useSelector((state : any) => state.pinnedCard.currentPinnedCard);

	const {
		options,
		entry,
		onRowClick,
		columns
	} = props;

	const [isHovered, setIsHovered] = React.useState(false);

	return (
		<tr onClick={() => {
			if (onRowClick) onRowClick(entry);
		}}
		onMouseEnter={() => setIsHovered(true)}
		onMouseLeave={() => setIsHovered(false)}
		className={' transition ease-in duration-100 hover:bg-neutral-80 text-left'}>
			{columns.map((col : any) => {
				if (col.render) return <td key={col.title} className={'p-2'}>{col.render(entry)}</td>;
				if (col.data_type === 'date') return <td key={col.title} className={`p-2 text-sm ${pinnedCard ? 'lg:max-w-[50px] ' : 'max-w-[100px] xl:max-w-[200px]'}`}>{entry[col.key] ? new Date(entry[col.key]).toLocaleDateString('en-US', { timeZone: 'UTC' }) : '—'}</td>;
				return <td key={col.title} className={`p-2 text-sm  ${pinnedCard ? 'max-w-none lg:max-w-[50px] xl:max-w-[75px] 2xl:max-w-none ' : 'max-w-none lg:max-w-[100px] xl:max-w-[100px] 2xl:max-w-[175px]'}`}>
					<div title={entry[col.key]}>{entry[col.key] !== null ? entry[col.key] : '—'}</div>
				</td>;
			})}
			{options && <td className={'text-left relative w-20 '}>
				<div className={`${isHovered ? 'block': 'hidden'}`}>
					{options(entry)}
				</div>
			</td>}
		</tr>
	);
};

export default TableRow;