import React, {useEffect} from 'react';
import {ClipboardDocumentIcon, CheckIcon} from '@icons';
import {motion, AnimatePresence} from 'framer-motion';
import TitledList from '@modules/common/components/TitledList/TitledList';
import {AtSymbolIcon} from '../../../@icons';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid';
import { ArrowUpRightIcon } from '@heroicons/react/16/solid';
import { DirectoryItemType } from '@modules/directory/types';

type DirectoryPinnedCardProps = {
  person: DirectoryItemType;
}
export default function DirectoryPinnedCard(props : DirectoryPinnedCardProps) {
	const [copiedTooltip, setCopiedTooltip] = React.useState<string | null>(null);
	const {person} = props;

	useEffect(() => {
		if (!copiedTooltip) return;
		setTimeout(() => {
			setCopiedTooltip(null);
		}, 3000);
	}, [copiedTooltip]);

	const handleCopyClick = (e : any, datum : any) => {
		navigator.clipboard.writeText(datum);
		setCopiedTooltip(datum);
	};

	return (
		<>
			<div style={{
				backgroundImage: `url('http://cdn.connect.creovia.io${person.imgPath}'), url('/images/user_gray.jpg')`,
			}} className={'flex justify-between items-end w-full h-[60%] bg-cover bg-center relative'}>
				<div className={'bg-gradient-to-t from-black to-transparent opacity-80 h-32 absolute bottom-0 w-full'}>
				</div>
				<div className={'h-fit z-10 text-lightMode-secondary p-4'}>
					<h3 className={'font-semibold text-lg '}>{person.first_name + ' ' + person.last_name}</h3>
					<h4>{person.position + ', ' + person.department}</h4>
				</div>
				<div className={'flex h-fit z-10 items-center text-lightMode-secondary p-4'}>
					<a href={`mailto:${person.email}`} className="flex z-0 items-center h-full p-2">
						<EnvelopeIcon className='bg-[#EFEFEF1F] aspect-square w-8 rounded h-full z-10 text-white cursor-pointer dark:text-darkMode-accent p-1' />
					</a>
					<a href={`tel:${person.tabs?.phone_primary}`} className="flex z-0 items-center h-full p-2">
						<PhoneIcon className='bg-[#EFEFEF1F] aspect-square w-8 rounded h-full z-10 text-white cursor-pointer dark:text-darkMode-accent p-1' />
					</a>
				</div>
			</div>

			<div className={'px-4'}>
				<TitledList title={'Contact'}>
					{person.email &&
						<>
							<div className={'font-semibold'}>
								<a href={`mailto:${person.email}`}>
									Email
								</a>
								<AnimatePresence>
									{
										copiedTooltip === person.email &&
									<motion.div
										initial={{opacity: 0, left: 50, top: -10}}
										animate={{opacity: 1, left: 50, top: -35}}
										exit={{opacity: 0, left: 50, top: -10}}
										className={' absolute border-2 border-green-300 bg-neutral-90 p-2 rounded-lg shadow-lg z-50 flex gap-2 items-center'}
									>
										Copied to clipboard
										<CheckIcon className={'h-5 text-green-500'}/>
									</motion.div>
									}
								</AnimatePresence>
								<div className='cursor-pointer hover:text-primary-40'
									onClick={(e) => {
										handleCopyClick(e, person.email);
									}}>
									{person.email}
									<ArrowUpRightIcon className="inline h-5"/>
								</div>
							</div>
						</>
					}

					{person.tabs ?
						<>
							<div className={'font-semibold'}>
								<a href={`tel:${person.tabs.phone_primary}`}>
									Phone
								</a>
								<AnimatePresence>
									{copiedTooltip === person.tabs.phone_primary ?
										<motion.div
											initial={{opacity: 0, left: 50, top: -10}}
											animate={{opacity: 1, left: 50, top: -35}}
											exit={{opacity: 0, left: 50, top: -10}}
											className={' absolute border-2 border-green-300 bg-neutral-90 p-2 rounded-lg shadow-lg z-50 flex gap-2 items-center'}
										>
										Copied to clipboard
											<CheckIcon className={'h-5 text-green-500'}/>
										</motion.div>
										: null
									}
								</AnimatePresence>
								<div className='cursor-pointer hover:text-primary-40'
									onClick={(e) => {
										handleCopyClick(e, person.tabs?.phone_primary);
									}}>
									{person.tabs.phone_primary}
									<ArrowUpRightIcon className="inline h-5"/>
								</div>
							</div>
						</>
						: null
					}

				</TitledList>
			</div>

			<div className={'px-4'}>
				<TitledList title={'Details'}>
					{person.position &&
						<>
							<span>Position </span>
							<span className={'font-semibold'}>{person.position}</span>
						</>
					}
					{person.specialty &&
						<>
							<span>Specialty </span>
							<span className={'font-semibold'}>{person.specialty}</span>
						</>
					}
					{person.department &&
						<>
							<span>Department </span>
							<span className={'font-semibold'}>{person.department}</span>
						</>
					}
					{person.location &&
						<>
							<span>Location </span>
							<span className={'font-semibold'}>{person.location}</span>
						</>
					}
				</TitledList>
			</div>

		</>
	);
}
