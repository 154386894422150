import { useState, useEffect } from 'react';
import {ObservationListType} from '../../../perform/pages/ObservationsDashboard/types';

// type useMutateDataParams = {
// 	mutateFunc: (data: ObservationListType[]) => []
// 	data: ObservationListType[]
// 	dependencies: []
// }
export default function useMutateData(mutateFunc: (data: []) => [], data: [], shouldMutate: boolean, dependencies: []) {
	const [mutatedData, setMutatedData] = useState([]);

	useEffect(() => {
		if (!shouldMutate) return;
		const result = mutateFunc(data);

		setMutatedData(result);

	}, [...dependencies]);

	return mutatedData;
}