import React, {useState} from 'react';
import {CheckIcon, MagnifyingGlassIcon} from '@icons';
import {useSearchQuery} from '@modules/common/hooks';
import { FilterOptions, FilterValue, Filters } from '@modules/common/types';


interface CheckboxesInputProps {
  filter: FilterValue;
  filterKey: keyof Filters;
  onClick: (filterKey: keyof Filters, filterCategory: FilterValue) => void;
  getFilterOptions: (filterKey: keyof Filters & string) => FilterOptions;
}

export default function CheckboxesInput({filter, filterKey, onClick, getFilterOptions}: CheckboxesInputProps) {
	const [searchQuery, setSearchQuery] = useState('');
	const filterOptions = React.useMemo(() => getFilterOptions(filterKey as string), [filter]);

	const isSelected = (filterCategory: number | string) => {
		return filter instanceof Set ? filter?.has(filterCategory) : filter === filterCategory;

	};

	const searchResults = useSearchQuery(filterOptions as string[], searchQuery);

	return (
		<>
			<div
				className="relative w-full min-w-max border-2 cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
				<MagnifyingGlassIcon
					className="pointer-events-none absolute inset-y-0 left-2 h-full w-5 text-gray-400"
					aria-hidden="true"
				/>
				<input autoFocus
					className="w-full min-w-max border-none py-2 text-sm leading-5 text-gray-900 rounded-lg focus:outline-primary-40 pl-8 pr-0"
					value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}/>
			</div>

			<ul className="max-h-72 mt-3">
				<h4 className="capitalize ">{(filterKey as string).replace('_', ' ')}</h4>
				{searchResults.length === 0 && searchQuery !== '' ? (
					<div className="relative cursor-default select-none py-2 ">
						Nothing found...
					</div>
				) : (
					<>
						{searchResults.slice(0, 100).map((filterCategory) => (
							<>
								<li
									key={filterCategory}
									className={`w-64  flex items-center rounded-lg mb-1 p-2  transition duration-150 ease-in-out hover:bg-neutral-70 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 cursor-pointer  ${isSelected(filterCategory) ? 'bg-primary-40 text-neutral-90 hover:bg-primary-50' : ''}`}
									onClick={() => onClick(filterKey, filterCategory)}
								>
									{filter instanceof Set ? filter?.has(filterCategory) ?
										<CheckIcon className="w-4 h-4 mr-2"/> : null : filter === filterCategory ?
										<CheckIcon className="w-4 h-4 mr-2"/> : null}
									{filterCategory}
								</li>
							</>
						))}
					</>
				)}
			</ul>
		</>

	);

}