import React from 'react';
import {ChevronDoubleDownIcon, ChevronDoubleUpIcon} from '@heroicons/react/20/solid';

type SortTogglePropsType = {
    column : any,
    currentSort: any,
    className: string | null
}

export default function SortToggle(props : SortTogglePropsType) {
	const {column, currentSort, className} = props;

	return (
		<div className={'flex flex-col justify-center items-center  dark:text-darkMode-secondary h-full ' + className}>
			{
				(column.title === currentSort?.by?.title) ?
					currentSort.direction > 0 ?
						<ChevronDoubleUpIcon className="h-3"/>
						:
						<ChevronDoubleDownIcon className="h-3"/> : null
			}
		</div>
	);
}