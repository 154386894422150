import { createSlice } from '@reduxjs/toolkit';

export interface HudState {
	showSideBar: boolean,
}

export const initialHudState = (): HudState => {
	return {
		showSideBar: false,
	};
};

export const hudSlice = createSlice({
	name: 'hud',
	initialState: initialHudState(),
	reducers: {
		toggleSideBar: (state) => {
			state.showSideBar = !state.showSideBar;
		},
	},
});

export const { toggleSideBar } = hudSlice.actions;
export default hudSlice.reducer;