import { CheckboxesInput, RangeInput, RangeCalendarInput } from './components';
import { FilterOptions, FilterValue, Filters } from '@modules/common/types';

interface FilterPanelProps {
	filter: FilterValue;
	filterKey: keyof Filters;
	getFilterOptions: (filterKey: keyof Filters) => FilterOptions;
	onClick: (filterKey: keyof Filters, newValue: FilterValue) => void;
	initialFilters: Filters;
}

export default function FilterPanel({filter, filterKey, getFilterOptions, onClick, initialFilters}: FilterPanelProps) {

	if (filter instanceof Set || typeof filter === 'string' || filter === null) {
		return <CheckboxesInput filter={filter} getFilterOptions={getFilterOptions} filterKey={filterKey} onClick={onClick} />;
	}
	else if (Array.isArray(filter)) return <RangeInput filter={filter} filterKey={filterKey} onClick={onClick} getFilterOptions={getFilterOptions} range={[(initialFilters[filterKey] as [number, number])[0], (initialFilters[filterKey] as [number, number])[1]]}/>;
	else if (typeof filter === 'object') return <RangeCalendarInput range={filter} filterKey={filterKey} onClick={onClick} />;
	else throw new TypeError('Filter type not supported');
}