import {ChangeEvent, useState} from 'react';
import { Link } from 'react-router-dom';
import { AppLayout } from '@modules/common/layouts';
import { CreoviaLoad } from '@modules/common/components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { login } from '@modules/auth/Login/api';
import {Credentials, User} from '@types';
import { authenticate } from 'services/api';
import { setAuthorizationHeader } from 'services/axiosInstance';
import UpdatePassword from '../UpdatePassword';

type ErrorResponseType = {
	data: {detail: string}
}
interface LoginErrorType extends Error {
	response: ErrorResponseType,
}

export default function Login() {
	const [error, setError] = useState<LoginErrorType | null>(null);

	const [formState, setFormState] = useState({
		email: '',
		password: '',
	});

	const queryClient = useQueryClient();
	const user = queryClient.getQueryData<User>(['user']);

	const { mutate, isPending } = useMutation({
		mutationFn: (values: Credentials) => login(values),
		onSuccess: async (data) => {
			localStorage.setItem('refresh', data.refresh);
			setAuthorizationHeader(data.access);
			const user = await authenticate();
			queryClient.setQueryData(['user'], user);
		},
		onError: (error: LoginErrorType) => {
			setError(error);
		}
	});

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		setFormState({
			...formState,
			[event.target.name]: event.target.value
		});
	}

	// Smells bc api requires email AND username
	function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
		mutate({ ...formState, username: formState.email });
	}

	if (user && user.force_password_change) return <UpdatePassword />;

	return (
		<div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
			<div className="sm:mx-auto sm:w-full sm:max-w-md">
				<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">

					{/* Card */}
					<div className="bg-neutral-90 px-6 py-12">
						<div className=" h-40 justify-center ">
							<img
								// className='h-48 w-75'
								src="/C2.svg"
								alt="Creovia"
							/>
						</div>
						<div>
							<h2 className="text-center text-2xl font-semibold tracking-tight text-neutral-5">{isPending ? 'Signing in to your account' : 'Sign in to your account'}
							</h2>
							{error ? <h2 className="p-4 text-center text-red-700">{`${error.response.data.detail}`}</h2> : null}
						</div>

						<div className="mt-8">
							<div className="mt-6">
								<form onSubmit={handleSubmit} className="space-y-6">
									<div
										className="relative rounded-md border px-3 py-2 focus-within:z-10 focus-within:border-primary-50 focus-within:ring-1 focus-within:ring-primary-50 bg-neutral-90 border-neutral-70">
										<label htmlFor="email"
											className="block text-xs font-medium text-neutral-40">
                                            Email
										</label>
										<input
											id="email"
											name="email"
											type="email"
											autoComplete="email"
											required
											value={formState.email}
											onChange={handleChange}
											className="block text-sm w-full border-0 p-0 text-neutral-5 placeholder-gray-500 focus:ring-0 sm:text-sm bg-neutral-90"
											placeholder="Enter your email"
										/>
									</div>
									<div
										className="relative rounded-md border px-3 py-2 focus-within:z-10 focus-within:border-primary-50 focus-within:ring-1 focus-within:ring-primary-50 bg-neutral-90 border-neutral-70">
										<label htmlFor="email"
											className="block text-xs font-medium text-gray-900 dark:text-variant-80">
                                            Password
										</label>
										<input
											id="password"
											name="password"
											type="password"
											autoComplete="current-password"
											required
											value={formState.password}
											onChange={handleChange}
											className="block text-sm w-full border-0 p-0 text-neutral-10 placeholder-gray-500 dark:text-neutral-100 focus:ring-0 sm:text-sm bg-neutral-90"
											placeholder="Enter your password"
										/>
									</div>
									<div className="flex items-center justify-between">
										<div className="flex items-center">
											<input
												id="remember-me"
												name="remember-me"
												type="checkbox"
												className="h-4 w-4 rounded border-gray-300 text-primary-40 focus:ring-primary-50"
											/>
											<label htmlFor="remember-me"
												className="ml-2 block text-sm text-variant-10 dark:text-variant-80">
                                                Remember me
											</label>
										</div>

										<div className="text-sm">
											<Link to="/reset"
												className="font-medium text-primary-40 hover:text-primary-50 dark:text-primary-60 dark:hover:text-primary-70">
                                                Forgot your password?
											</Link>
										</div>
									</div>

									<div>
										<button
											type="submit"
											className="flex w-full justify-center rounded-md border border-transparent bg-primary-40 py-3 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-50"
										>
											{isPending ? 'Signing in...' : 'Sign In'}
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}