import { useForm } from '@modules/common/hooks';
import {FormEvent, useState} from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updatePassword } from './api';
import {CreoviaLoad} from '../../common/components';
import {Link} from 'react-router-dom';

interface FormState {
	password: string;
	password_confirm: string;
}

export default function UpdatePassword() {
	const {formState, handleChange} = useForm<FormState>({
		password: '',
		password_confirm: '',
	});

	const [errorMessage, setErrorMessage] = useState('');


	const queryClient = useQueryClient();

	const { mutate, isPending } = useMutation({
		mutationFn: updatePassword,
		onSuccess: async (user) => {
			queryClient.setQueryData(['user'], user);
		},
	});

	/**
	 * Converts an array of strings to a comma separated string
	 * @param arr - Array of strings
	 */
	const toCommaSeparatedString = (arr: string[]) => {
		if (!arr) return [];

		let string = '';

		arr.map((char) => {
			string += char + ', ';
		});

		return string.slice(0, -2);
	};

	const submitForm = async (password: string, password_confirm: string) => {
		mutate({password, password_confirm});
	};

	const isValidPassword = (password: string, password_confirm: string) => {
		// eslint-disable-next-line no-control-regex
		const regex = /[^\x00-\x7F]/g;
		const illegalChars = toCommaSeparatedString(password.match(regex) as string[]);

		if (/\s/g.test(password)) { // Regex checks for whitespace
			setErrorMessage('Password cannot contain spaces');
			return false;
		} else if (illegalChars.length > 0) {
			setErrorMessage(`Password cannot contain the following characters: ${illegalChars}`); // a-Z 0-9 !@#$%^ -_
			return false;
		} else if (password !== password_confirm) {
			setErrorMessage('Passwords do not match');
			return false;
		} else if (password.length <= 8) {
			setErrorMessage('Password must be longer than 8 characters.');
			return false;
		}

		return true;
	};

	const signOutUser = async () => {
		localStorage.removeItem('refresh');
		return queryClient.invalidateQueries({ queryKey: ['user'] });
	};

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const {password, password_confirm} = formState;

		try {
			setErrorMessage('');

			if (isValidPassword(password, password_confirm)) {
				await submitForm(password, password_confirm);
			}

		} catch (error) {
			setErrorMessage('There was an error resetting your password.');
		}
	};

	return (
		<>
			<div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<div className="bg-white dark:bg-neutral-12 px-6 py-16 shadow sm:rounded-lg">
						<div className=" h-40 justify-center ">
							<img
								// className='h-48 w-75'
								src="/C2.svg"
								alt="Creovia"
							/>
						</div>
						<div>
							<h2 className="text-2xl font-bold tracking-tight dark:text-white">
								{errorMessage ? errorMessage : 'Reset Password'}
							</h2>
							<div className="ext-sm tracking-tight dark:text-white">
								{errorMessage ? ' ' : 'Please use the form below to update your password'}
							</div>
						</div>

						<div className="mt-8">
							<div className="mt-6">
								<form onSubmit={handleSubmit} className="space-y-6">
									<div
										className="relative rounded-md border border-variant-90 px-3 py-2 focus-within:z-10 focus-within:border-primary-50 focus-within:ring-1 focus-within:ring-primary-50 dark:bg-variant-20 dark:border-none">
										<label htmlFor="password"
											className="block text-sm font-medium text-gray-900 dark:text-variant-80">
                                            New Password
										</label>
										<input
											id="password"
											name="password"
											type="password"
											autoComplete="current-password"
											required
											value={formState.password}
											onChange={handleChange}
											className="block text-sm w-full border-0 p-0 text-neutral-10 placeholder-gray-500 dark:text-neutral-100 focus:ring-0 sm:text-sm dark:bg-variant-20"
											placeholder="Enter your new password"
											style={{height: '30px'}}
										/>
										<div className="italic text-xs tracking-tight dark:text-white mt-2">
                                            Passwords should be a minimum of 8 characters, with no spaces.
										</div>
									</div>
									<div
										className="relative rounded-md border border-variant-90 px-3 py-2 focus-within:z-10 focus-within:border-primary-50 focus-within:ring-1 focus-within:ring-primary-50 dark:bg-variant-20 dark:border-none">
										<label htmlFor="password"
											className="block text-sm font-medium text-gray-900 dark:text-variant-80">
                                            Confirm New Password
										</label>
										<input
											id="password_confirm"
											name="password_confirm"
											type="password"
											autoComplete="current-password"
											required
											value={formState.password_confirm}
											onChange={handleChange}
											className="block text-sm w-full border-0 p-0 text-neutral-10 placeholder-gray-500 dark:text-neutral-100 focus:ring-0 sm:text-sm dark:bg-variant-20"
											placeholder="Confirm your new password"
											style={{height: '30px'}}
										/>
										<div className="italic text-xs tracking-tight dark:text-white mt-2">
                                            Enter the same password as before, for verification.
										</div>
									</div>
									<div>
										<button
											type="submit"
											disabled={isPending}
											className="flex w-full justify-center rounded-md border border-transparent bg-primary-40 py-3 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-50"
										>
											{isPending ? 'Loading...' : 'Reset Password'}
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div onClick={signOutUser}>
						<span aria-hidden="true" className="text-base font-medium text-primary-40 hover:text-primary-50"> &larr;</span>
                                Go back
					</div>
				</div>
				<div className={`h-full ${isPending ? '' : 'hidden'}`}>
					<CreoviaLoad />
				</div>
			</div>
		</>
	);
}