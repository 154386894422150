import {Tokens, User} from '@types';
import {refreshAccessToken} from '../axiosInstance';
import callAPI from '../index';
import { authEndpoints } from './endpoints';

const refresh = async (): Promise<Tokens> => {
	return await refreshAccessToken();
};

const authenticate = async (): Promise<User> => {
	return await callAPI<User>(authEndpoints.authenticate);

};

export { refresh, authenticate };