import { useState, useMemo } from 'react';
import {isWithinFilterDateRange} from './utils/dateFilterUtils';
import _ from 'lodash';
import {
	Datum,
	Filters,
	FilterValue,
	DateFilter,
	FilteredSets,
	FilterState,
	FilterOptions
} from '@modules/common/types/Filters/Filters';
import { sortStringWithNumbersLast } from './utils';

/**
 *
 * Hooks takes in data (probably an array of objects), and initialFilters (an object with FilterKey, FilterValue pairs)
 *
 * E.g.
 * data: [
 *   {name: 'Jake', age: 30, joinDate: 1/01/2001},
 *   {name: 'John', age: 66, joinDate: 2/02/2002}
 *   ]
 *
 * initialFilters: {
 *   name: new Set(['Jake', 'John']),
 *   age: [0, 65],
 *   joinDate: {
 *     type: 'date',
 *     start: null,
 *     end: null
 *   }
 * }
 *
 * From this, the second datum, 'John' would be filtered out, because his age is outside the range in our age filter,
 * though, typically, the 'initialFilters' would not filter anyone out at the start.  But the filters object does not differ in structure from the initialFilters
 *
 *
 * @param data - data to be filtered
 * @param initialFilters - initial state of filters
 * return:
 *    filteredData - data after filtering applied
 *    initialFilters - the initialFilters passed in originally
 *    filters - our filters being applied
 *    updateFilters - function to update filters state
 *    resetFilter - reset a specific filter (e.g. name or age or joinDate)
 *    resetFilters - resets all filters back to initialFilter state
 *    getFilterOptions - function for getting available filter options for passed filter (e.g. name or age or joinDate)
 */
export default function useFilter<T extends Datum>(data: T[], initialFilters: Filters) {
	const [filters, setFilters] = useState<Filters>(initialFilters);
	const filterState = useMemo(() => handleFilterState(), [data, filters]);

	function resetFilters() {
		setFilters(initialFilters);
	}

	function updateFilters (filterKey: keyof Filters, newFilterValue: FilterValue) {

		let updatedFilters = {...filters} as Filters;

		if (updatedFilters[filterKey] instanceof Set) {
			/** Update values based on what is clicked */
			if ((updatedFilters[filterKey] as Set<string | number>).has(newFilterValue as string | number)) {
				updatedFilters[filterKey] = new Set((updatedFilters[filterKey] as Set<string | number>));
				(updatedFilters[filterKey] as Set<string | number>).delete(newFilterValue as string | number);
			} else {
				updatedFilters[filterKey] = new Set((updatedFilters[filterKey] as Set<string | number>));
				(updatedFilters[filterKey] as Set<string | number>).add(newFilterValue as string | number);
			}

		} else if (Array.isArray(updatedFilters[filterKey])) {
			updatedFilters[filterKey] = newFilterValue;

		} else if (typeof updatedFilters[filterKey] === 'object' && updatedFilters[filterKey] !== null) {
			updatedFilters[filterKey] = {...(updatedFilters[filterKey] as DateFilter), ...(newFilterValue as DateFilter)};
		} else {
			/** Update values based on what is clicked */
			if (updatedFilters[filterKey] === newFilterValue) updatedFilters[filterKey] = null;
			else updatedFilters = {...updatedFilters, [filterKey]: newFilterValue};
		}

		setFilters(updatedFilters);
	}

	function resetFilter(filterKey: keyof Filters) {
		const updatedFilters = { ...filters, [filterKey]: initialFilters[filterKey] };
		setFilters(updatedFilters);
	}

	function handleFilterState(): FilterState<T> {
		// If no filters are applied, return all data and all filter options
		if (_.isEqual(filters, initialFilters)) {
			return {
				filteredData: data,
				filteredSets: null,
			};
		}

		const filteredSets: FilteredSets = {};

		const updatedFilteredData: T[] = data.filter((item, index) => {
			let meetCriteria = true;

			Object.keys(filters).forEach(key => {
				const filter = filters[key];
				const value = item[key];

				if (!_.isEqual(filter, initialFilters[key])) {
					if (!filteredSets[key]) {
						filteredSets[key] = [];
					}

					// Check if item meets filter criteria for this filter
					// (method for checking depends on filter type)
					if (Array.isArray(filter)) {
						if (value >= filter[0] && value <= filter[1]) {
							filteredSets[key].push(index);
						} else {
							meetCriteria = false;
						}
					} else if (typeof filter === 'string') {
						if (value.toString().toLowerCase() === filter.toLowerCase()) {
							filteredSets[key].push(index);
						} else {
							meetCriteria = false;
						}
					} else if (filter instanceof Set) {
						if (filter.size === 0 || filter.has(value)) {
							filteredSets[key].push(index);
						} else {
							meetCriteria = false;
						}
					} else if (filter instanceof Object && filter?.type === 'date') {
						if (isWithinFilterDateRange(filter, item[key])) {
							filteredSets[key].push(index);
						} else {
							meetCriteria = false;
						}
					}
				}
			});

			return meetCriteria;

		});

		return {
			filteredData: updatedFilteredData,
			filteredSets,
		};

	}

	function getFilterOptions(filterKey: keyof T & keyof Filters): FilterOptions {
		if (Array.isArray(filters[filterKey])) {
			if (filterState.filteredSets === null) {
				return filters[filterKey] as [number, number];
			}

			let filteredDataSansApplyingCurrentFilter: number[] = Array.from(Array(data.length).keys());

			Object.entries(filterState.filteredSets).forEach(([key, indices]) => {
				if (key !== filterKey) {
					if (filteredDataSansApplyingCurrentFilter.length === 0) {
						filteredDataSansApplyingCurrentFilter = [ ...indices ];
					} else {
						filteredDataSansApplyingCurrentFilter = filteredDataSansApplyingCurrentFilter.filter(v => indices.includes(v));
					}
				}
			});

			let minValue = 0, maxValue = 0;
			filteredDataSansApplyingCurrentFilter.forEach((index) => {
				if(!minValue || data[index][filterKey] < minValue) {
					minValue = data[index][filterKey];
				}
				if(!maxValue || data[index][filterKey] > maxValue) {
					maxValue = data[index][filterKey];
				}
			});

			return [minValue, maxValue];
		}

		const filterOptions = new Set<string | number>();

		if (filterState.filteredSets === null) {
			data.forEach((item) => {
				filterOptions.add(item[filterKey]);
			});

			return Array.from(filterOptions).sort(sortStringWithNumbersLast);
		}

		let filteredDataSansApplyingCurrentFilter: number[] = Array.from(Array(data.length).keys());

		Object.entries(filterState.filteredSets).forEach(([key, indices]) => {
			if (key !== filterKey) {
				if (filteredDataSansApplyingCurrentFilter.length === 0) {
					filteredDataSansApplyingCurrentFilter = [ ...indices ];
				} else {
					filteredDataSansApplyingCurrentFilter = filteredDataSansApplyingCurrentFilter.filter(v => indices.includes(v));
				}
			}
		});

		filteredDataSansApplyingCurrentFilter.forEach((index) => {
			filterOptions.add(data[index][filterKey]);
		});

		return Array.from(filterOptions).sort(sortStringWithNumbersLast);
	}

	return {
		filteredData: filterState.filteredData,
		initialFilters,
		filters,
		updateFilters,
		resetFilter,
		resetFilters,
		getFilterOptions,
	};
}