import React from 'react';
import { SidebarHamburger, SearchBar, UserIcon } from './components';


export default function TopBar() {

	return (
		<div className="pt-8 px-3 lg:px-0 gap-5 w-full flex">
			<SidebarHamburger/>
			<SearchBar/>

			<div className="flex items-center gap-x-4 lg:gap-x-6">
				<UserIcon/>
			</div>
		</div>
	);
}