import { useMemo } from 'react';

export default function useSearchQuery<T>(data: T[] = [], query: string, fields?: (keyof T & string)[]) {
	const filteredData = useMemo<T[]>(() => filterDataByQuery(), [data, query]);

	function filterDataByQuery() {
		if (query && data.length > 0) {
			const queryLowerCase = query.toLowerCase();
			const dataFilteredByQuery = [...data].filter((item) => {

				if(fields === undefined) {

					if (typeof item === 'string') {
						return item.toLowerCase().includes(queryLowerCase);
					} else {
						return item?.toString().toLowerCase().includes(queryLowerCase);
					}

				} else {
					const iterator = fields;
					return iterator?.some((field) => {
						const fieldValue = item[field];

						if (typeof fieldValue === 'string' || typeof fieldValue === 'number') {
							return fieldValue.toString().toLowerCase().includes(queryLowerCase);
						} else if (Array.isArray(fieldValue)) {
							return fieldValue.some((v) => {
								return v.toString().toLowerCase().includes(queryLowerCase);
							});
						} else {
							return Object.values(fieldValue as object).some((v) => {
								return (v.toString().toLowerCase().includes(queryLowerCase));
							});
						}
					});
				}

			});

			return dataFilteredByQuery;
		} else {
			return data;
		}
	}

	return filteredData;
}