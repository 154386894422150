import React from 'react';
import { Bars3Icon } from '@icons';
import {useDispatch} from 'react-redux';
import {toggleSideBar} from 'store/slices/hudSlice';


export default function SidebarHamburger() {
	const dispatch = useDispatch();

	const handleHamburgerClick = () => {
		dispatch(toggleSideBar());
	};

	return (
		<div
			className={'bg-neutral-90 shadow  h-full aspect-square p-2 rounded-md lg:hidden'}
			onClick={() => handleHamburgerClick()}
		>
			<Bars3Icon className={'h-8 text-neutral-50'}/>
		</div>
	);
}