type ErrorName = 'InternalError' | 'AuthenticationError' | 'InvalidRefreshTokenError';
export class ConnectError extends Error {
	name: ErrorName;
	message: string;
	cause: any;

	constructor(name: ErrorName, message: string, cause: any) {
		super();
		this.name = name;
		this.message = message;
		this.cause = cause;
	}
}