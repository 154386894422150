export function isWithinFilterDateRange(filterDates: any, itemDate: string) {
	const startDate = filterDates.start;
	const endDate = filterDates.end;

	if (!startDate && !endDate) return true;
	if (!startDate || !endDate) return false; // Possible cause of bugs? idk - smells

	let startAsInt, endAsInt, comparatorDateAsInt;
	try {
		startAsInt = new Date(startDate.year, startDate.month - 1, startDate.day).getTime();
		endAsInt = new Date(endDate.year, endDate.month - 1, endDate.day).getTime();
	} finally {
		comparatorDateAsInt = new Date(itemDate).getTime();
	}

	if (!startDate) return comparatorDateAsInt <= endAsInt;
	if (!endDate) return comparatorDateAsInt >= startAsInt;

	return comparatorDateAsInt >= startAsInt && comparatorDateAsInt <= endAsInt;
}