import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {changePinnedCard} from 'store/slices/pinnedCardSlice';
import DirectoryPinnedCard from './DirectoryPinnedCard/DirectoryPinnedCard';
import EvaluationPinnedCard from './EvaluationPinnedCard';
import {XMarkIcon} from '@icons';
import MagnifyPinnedCard from './MaginfyPinnedCard/MagnifyPinnedCard';
import {RootState} from 'store';
export default function PinnedCards() {
	const pinnedCard: any = useSelector<RootState>(state => state.pinnedCard.currentPinnedCard);
	const dispatch = useDispatch();
	const renderCurrentCardComponent = () => {
		switch (pinnedCard.type) {
		case 'directory':
			return <DirectoryPinnedCard person={pinnedCard.data}/>;
		case 'evaluation':
			return <EvaluationPinnedCard data={pinnedCard.data}/>;

		case 'magnify':
			return <MagnifyPinnedCard student={pinnedCard.data} cardURL={pinnedCard.url}/>;
		// case 'testCard':
		// 	return <TestPinnedCard person={du`mmyData}/>;
		default:
			return null;
		}
	};
	return pinnedCard ? (
		<>
			<div className={'w-full absolute bg-neutral-95 h-full z-40 bg-opacity-60 lg:hidden '}
				onClick={() => dispatch(changePinnedCard(null))}></div>
			<div
				className={`${pinnedCard ? 'w-[28%] max-w-[500px] min-w-[400px] absolute right-0 h-full bg-neutral-90 z-50 lg:relative lg:h-auto' : ' w-0 '}`}
				style={{visibility: pinnedCard ? 'visible' : 'hidden'}}
			>
				<div className={'relative flex flex-col h-screen gap-5 overflow-y-auto '}>
					<div
						className={'w-10 cursor-pointer aspect-square p-1 absolute right-5 top-5 bg-neutral-80 rounded z-10 ease-in-out duration-200 hover:bg-neutral-70  text-neutral-60 hover:text-primary-40'}
						onClick={() => {
							dispatch(changePinnedCard(null));
						}}>
						<XMarkIcon className=""/>
					</div>
					{renderCurrentCardComponent()}
				</div>
			</div>
		</>
	)
		: null;
}