import { useEffect, useRef, useState } from 'react';

interface LazyLoadHookProps<T> {
  data: T[];
  itemsPerRender: number;
}

interface LazyLoadHookResult<T> {
  lazyData: T[];
  targetRef: React.RefObject<HTMLDivElement>;
}

/**
 * A hook that fetches more data when the target element is visible
 * @param data: The data to be rendered
 * @param itemsPerRender: The number of items to load per trigger
 */
const useLazyLoad = <T>({ data, itemsPerRender }: LazyLoadHookProps<T>): LazyLoadHookResult<T> => {
	const [lazyData, setLazyData] = useState<T[]>([]);
	const [loadedCount, setLoadedCount] = useState(0);
	const targetRef = useRef<HTMLDivElement>(null);

	/**
	 * Reset the lazy data and loaded count when the data changes (
	 */
	useEffect(() => {
		setLazyData([]);
		setLoadedCount(0);
	}, [data]);

	/**
	 * Fetch more data when the target element is visible
	 */
	useEffect(() => {
		const fetchData = async () => {
			// Simulate fetching more data
			// You should replace this with your actual data fetching logic
			// For now, it just returns itemsPerRender number of items at a time
			const newData = data.slice(loadedCount, loadedCount + itemsPerRender);
			setLazyData((prevData) => [...prevData, ...newData] as T[]);
			setLoadedCount((prevCount) => prevCount + itemsPerRender);
		};

		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					fetchData();
				}
			});
		});

		if (lazyData.length === 0 && data.length > 0) fetchData();

		if (targetRef.current) {
			observer.observe(targetRef.current);
		}

		return () => {
			observer.disconnect();
		};
	}, [data, itemsPerRender, loadedCount, lazyData.length]);

	return { lazyData, targetRef };
};

export default useLazyLoad;