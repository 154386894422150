import {useQuery} from '@tanstack/react-query';
import {fetchDashboardCard} from '../../../@modules/magnify/Components/fetchDashboard';
import {DashboardCardType, DashboardDataType} from '../../../@modules/magnify/Components/types/DashboardTypes';
import React, {ReactElement} from 'react';
import {Tab} from '@headlessui/react';
import {AppLayout} from '../../../@modules/common/layouts';
import {CreoviaLoad} from '../../../@modules/common/components';

type MagnifyPinnedCardProps = {
	student: DashboardDataType
	cardURL: string
}
export default function MagnifyPinnedCard({student, cardURL}: MagnifyPinnedCardProps) {
	const cardID = student.ID.toString();
	const cardEndpoint = `${cardURL}${cardID}`;
	
	const {data: studentData, isLoading} = useQuery({
		queryKey: ['card', cardID],
		queryFn: () => fetchDashboardCard(cardEndpoint)
	});
	
	if (isLoading) return (
		<CreoviaLoad />
	);
	
	if (!studentData) throw new Error('card data is undefined');
	
	const tierClassnames = ' w-fit p-1 px-2 text-xs font-semibold rounded-full  bg-opacity-30  ';
	const tierRenders: Record<number, ReactElement> = {
		0: <div className={tierClassnames + ' bg-primary-40 text-primary-20 dark:text-primary-60'}>No
            Risk</div>,
		1: <div
			className={tierClassnames + ' bg-green-500 text-green-900 dark:text-green-600'}>Minimal
            Risk</div>,
		2: <div
			className={tierClassnames + ' bg-yellow-500 text-yellow-900 dark:text-yellow-600'}>Low
            Risk</div>,
		3: <div className={tierClassnames + ' bg-orange-500 text-orange-900 dark:text-orange-600'}>Medium
            Risk</div>,
		4: <div
			className={tierClassnames + ' bg-red-500 text-red-900 dark:text-red-600'}>High
            Risk</div>
	};
	
	const nthNumber = (number: number) => {
		if (!number) return '';
		if (number > 3 && number < 21) return 'th Grade';
		switch (number % 10) {
		case 1:
			return 'st Grade';
		case 2:
			return 'nd Grade';
		case 3:
			return 'rd Grade';
		default:
			return 'th Grade';
		}
	};
	
	function classNames(...classes: string[]) {
		return classes.filter(Boolean).join(' ');
	}

	const getTabHeaders = (key: string) => {
		switch (key) {
		case 'status':
			return 'Overview';
		case 'grades':
			return 'Grades';
		case 'behavior':
			return 'Behavior';
		case 'assessment':
			return 'Assessments';
		default:
			return;
		}
	};
	
	const renderTabContent = (key: string) => {
		if (studentData[key as keyof DashboardCardType].length < 1) return;
		return (
			<div>
				<div className={'w-full flex flex-col gap-3'}>
					<table>
						<thead>
							<tr className={'text-xs whitespace-nowrap text-left'}>
								{Object.keys((studentData[key as keyof DashboardCardType] as Record<string, string>[])[0]).map((col) => {
									if (col === 'teacher') return <th key={col}
										className={'text-xs whitespace-nowrap text-left p-2'}>Teacher</th>;
									if (col === 'coursename') return <th key={col}
										className={'text-xs whitespace-nowrap text-left p-2'}>Course</th>;
									if (col === 'progressscore') return <th key={col}
										className={'text-xs whitespace-nowrap text-left p-2'}>Score</th>;
									if (col === 'title') return <th key={col}
										className={'text-xs whitespace-nowrap text-left p-2'}>Title</th>;
									if (col === 'incident') return <th key={col}
										className={'text-xs whitespace-nowrap text-left p-2'}>Incident</th>;
									if (col === 'submittedby') return <th key={col}
										className={'text-xs whitespace-nowrap text-left p-2'}>Submitted
                                    By</th>;
									if (col === 'resolutionname') return <th key={col}
										className={'text-xs whitespace-nowrap text-left p-2'}>Resolution</th>;
									if (col === 'testtype') return <th className={'text-xs whitespace-nowrap text-left p-2'} key={col}>Type</th>;
									if (col === 'benchmark') return <th className={'text-xs whitespace-nowrap text-left p-2'} key={col}>Test</th>;
									if (col === 'assessmentname') return <th className={'text-xs whitespace-nowrap text-left p-2'} key={col}>Name</th>;
									if (col) return <th key={col}
										className={'text-xs whitespace-nowrap text-left p-2'}>{col}</th>;
								})}
							</tr>
						</thead>
						<tbody>{ studentData[key as keyof DashboardCardType].length > 0 && (studentData[key as keyof DashboardCardType] as Record<string, string>[]).map((item, i) => {
							return (
								<tr key={i} className={`${i % 2 === 0 ? 'bg-neutral-80' : ''}`}>
									{Object.keys((studentData[key as keyof DashboardCardType] as Record<string, string>[])[0]).map((col, i: number) => {
										return (
											<td key={col} className={'text-left text-xs p-2'}>{item[col]}</td>
										);
									})}
								</tr>
							);})}
						</tbody>
					</table>
				</div>
			</div>
		);
	};
	
	const formatDate = (date: string) => {
		if (!date) return 'No date';
		const year = date.split(' ').splice(0, 1)[0].split('-')[0].slice(2, 4);
		const month = date.split(' ').splice(0, 1)[0].split('-')[1];
		const day = date.split(' ').splice(0, 1)[0].split('-')[2];
		return Number(month) + '/' + Number(day) + '/' + year;
	};

	const getMutatedKeys = (key: string) => {
		switch (key) {
		case 'absences':
			return 'Absences';
		case 'campus':
			return 'Campus';
		case 'firstname':
			return 'First Name';
		case 'lastname':
			return 'Last Name';
		case 'grade':
			return 'Grade';
		case 'numclassesfailing':
			return '# of F\'s';
		case 'tardies':
			return 'Tardies';
		case 'tier':
			return 'Risk Factor';
		case 'numclassesd':
			return '# of Ds';
		default:
			return key;

		}
	};
	
	const TabClassNames = {
		selected: 'w-full rounded-lg py-2 text-sm font-medium leading-5 focus:outline-none transition duration-200 bg-primary-40 text-white shadow',
		notSelected: 'w-full rounded-lg py-2 text-sm font-medium leading-5 focus:outline-none transition duration-200 text-neutral-800 dark:text-lightMode-secondary dark:hover:bg-darkMode-quaternary hover:bg-lightMode-secondary'
	};
	
	return (
		<>
			<div className={'flex flex-col mt-4 p-4 items-center  mx-4 rounded-xl gap-2 dark:text-lightMode-secondary'}>
				<div style={{
					backgroundImage: `url('${studentData.image}'), url('/images/user_gray.jpg')`,
				}} className={'h-40 aspect-square bg-cover bg-center relative rounded-full '}>
				</div>
				<div className={'w-fit min-w-[200px] mx-auto flex flex-col gap-1 text-center'}>
					<h3 className={'font-semibold'}>{studentData.status.firstname + ' ' + studentData.status.lastname}</h3>
					<h4 className={'text-sm'}>{studentData.status.campus}</h4>
					<div className={'flex justify-between items-center'}>
						<h4 className={'text-sm'}>{!Number(studentData.status.grade) ? studentData.status.grade : Number(studentData.status.grade) + '' + nthNumber(Number(studentData.status.grade))}</h4>
						{tierRenders[studentData.status.tier as keyof Record<number, ReactElement>]}
					</div>
				</div>

				{studentData.sped.length > 0 ? <div className={'text-xs flex flex-col w-full mt-5 rounded dark:border-2 dark:border-darkMode-b'}>
					<div className={' bg-lightMode-secondary dark:border-darkMode-b border-b-2  dark:bg-darkMode-secondary font-semibold p-2 rounded-t-lg'}>Subgroups:</div>
					<div className={' bg-lightMode-secondary dark:bg-darkMode-secondary ' +
                            ' p-2 rounded-b-lg'}>
						{studentData.sped.map((item, i) => {
							return (
								<div className={'flex justify-between'} key={i}>
									<div>{item.spedflag}</div>
									{item.planstartdate !== null && item.planenddate !== null ?
										<div>{formatDate(item.planstartdate) + ' - ' + formatDate(item.planenddate)}</div> : null}
								</div>
							);
						})}
					</div>
				</div> : null}
				<div className={'w-full max-w-md '}>
					<Tab.Group>
						<Tab.List
							className={'flex space-x-1 rounded-xl   mb-2 '}>
							{Object.keys(studentData).map((key) => {
								if (key === 'sped') return;
								if (key === 'image') return;
								if (key === 'behavior' && studentData[key].length <= 0) return;
								return <Tab
									key={key}
									title={getTabHeaders(key)}
									className={({selected}) =>
										classNames(
											'w-full rounded-lg py-2 text-sm font-medium leading-5 max-w-md px-2',
											' focus:outline-none transition duration-200',
											selected
												? 'bg-primary-40  shadow text-neutral-90'
												: 'text-neutral-5 hover:bg-primary-40/50'
										)
									}>
									{getTabHeaders(key)}
								</Tab>;
							})}
						</Tab.List>
						<Tab.Panels className={'bg-neutral-70 rounded-lg overflow-x-scroll'}>
							{Object.keys(studentData).map((key, i) => {
								if (key === 'sped') return;
								if (typeof studentData[key as keyof DashboardCardType] === 'string') return;
								if (studentData[key as keyof DashboardCardType].length <= 0) return;
								if (key === 'status') {
									return (
										<Tab.Panel key={i}
											className={''}>
											<div>
												<div
													className={'w-full gap-2 rounded-lg items-baseline text-sm'}>
													{Object.keys(studentData.status).map((key, i ) => {
														return (
															<div key={key} className={`flex ${i % 2 === 0 ? '': 'bg-neutral-80'} ${i === 0 ? 'rounded-t' : ''} ${i === Object.keys(studentData.status).length - 1 ? 'rounded-b' : ''}`}>
																<div
																	className={' p-2 w-24'}>
																	{getMutatedKeys(key)}
																</div>
																{key === 'tier'
																	?
																	<div
																		className={'p-2'}>{tierRenders[studentData.status[key] as keyof Record<number, ReactElement>]}</div>
																	:
																	<div
																		className={'p-2'}>{studentData.status[key]}</div>
																}
															</div>
														);
													}
													)}
												</div>
											</div>
										</Tab.Panel>
									);
								}
								return (
									<Tab.Panel key={i}
										className={classNames(
											'',
										)}>
										{renderTabContent(key)}
									</Tab.Panel>
								);
							})}

						</Tab.Panels>
					</Tab.Group>
				</div>

			</div>
		</>
	);
}