import {useLocale} from '@react-aria/i18n';
import {XCircleIcon, PlusCircleIcon} from '@icons';
import {Popover, Transition} from '@headlessui/react';
import React from 'react';
import {FilterPanel} from './components';
import _ from 'lodash';
import { Filters as FiltersType, FilterValue, DateFilter, FilterOptions } from '@modules/common/types';

interface FiltersProps {
  filters: FiltersType;
  initialFilters: FiltersType;
  resetFilters: () => void;
  resetFilter: (filterKey: keyof FiltersType) => void;
  getFilterOptions: (filterKey: keyof FiltersType) => FilterOptions;
	updateFilters: (filterKey: keyof FiltersType, newFilterValue: FilterValue) => void;
}

export default function Filters({filters, resetFilters, resetFilter, updateFilters, getFilterOptions, initialFilters}: FiltersProps) {
	const locale = useLocale();

	function handleFilterClick(filterKey: keyof FiltersType, newValue: FilterValue) {
		updateFilters(filterKey, newValue);
	}

	function isFilterActive(filterKey: keyof FiltersType) {
		if (filters[filterKey] instanceof Set) {
			return (filters[filterKey] as Set<string | number>).size > 0;
		} else if (Array.isArray(filters[filterKey])) {
			return (filters[filterKey] as [number, number])[0] > (initialFilters[filterKey] as [number, number])[0] || (filters[filterKey] as [number, number])[1] < (initialFilters[filterKey] as [number, number])[1];
		} else if (typeof filters[filterKey] === 'object' && filters[filterKey] !== null) {
			return (filters[filterKey] as DateFilter).start !== null || (filters[filterKey] as DateFilter).end !== null;
		} else {
			return filters[filterKey] !== null;
		}
	}

	const dateToString = (date: any, options = {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	} as Intl.DateTimeFormatOptions) => {
		return new Intl.DateTimeFormat(locale.locale, options).format(date.toDate(Intl.DateTimeFormat().resolvedOptions().timeZone));
	};

	function getFilterCrumbTitle(filterKey: keyof FiltersType): string {
		if (filters[filterKey] instanceof Set) {
			return `${filterKey} (${(filters[filterKey] as Set<number | string>).size})`;
		} else if (Array.isArray(filters[filterKey])) {
			return `${filterKey} ${(filters[filterKey] as [number, number])[0]} - ${(filters[filterKey] as [number, number])[1]}`;
		} else if (typeof filters[filterKey] === 'object' && filters[filterKey] !== null) {
			const startDate = (filters[filterKey] as DateFilter).start;
			const endDate = (filters[filterKey] as DateFilter).end;

			return `${dateToString(startDate)} - ${dateToString(endDate)}`;
		} else {
			return `${filters[filterKey]}`;
		}
	}

	return (
		<>
			<div className={' flex justify-between gap-10 w-full items-start'}>
				<ul className="flex flex-wrap gap-2 justify-left">
					{Object.keys(filters).map(filterKey => (
						<li key={filterKey}>
							<Popover className="relative">
								<Popover.Button
									className={`inline-flex items-center border rounded-lg p-1 px-2 space-x-1 border-neutral-70 ${isFilterActive(filterKey) ? 'bg-primary-40 text-neutral-90' : ''} `}>

									{
										isFilterActive(filterKey)
											?
											<>
												<span className="capitalize">{getFilterCrumbTitle(filterKey).replaceAll('_', ' ')}</span>
												<XCircleIcon
													onClick={(e) => {
														e.preventDefault();
														resetFilter(filterKey);
													}}
													className="h-4 w-4 "/>
											</>
											:
											<>
												<span className="capitalize">{filterKey.replaceAll('_', ' ')}</span>
												<PlusCircleIcon
													className="h-4 w-4 "/>
											</>
									}

								</Popover.Button>

								<Transition
									as={React.Fragment}
									enter="transition ease-out duration-200"
									enterFrom="opacity-0 translate-y-1"
									enterTo="opacity-100 translate-y-0"
									leave="transition ease-in duration-150"
									leaveFrom="opacity-100 translate-y-0"
									leaveTo="opacity-0 translate-y-1"
								>
									<Popover.Panel className="absolute left-0 z-10 mt-3 px-4 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-neutral-80 py-4 overflow-auto">
										<FilterPanel filterKey={filterKey as keyof FiltersType} filter={filters[filterKey]} getFilterOptions={getFilterOptions} onClick={handleFilterClick} initialFilters={initialFilters} />
									</Popover.Panel>
								</Transition>
							</Popover>
						</li>
					))}
				</ul>
				{!_.isEqual(filters, initialFilters) ? <button
					onClick={resetFilters}
					className={'inline-flex items-center border-[1px] rounded-lg p-1 px-2 space-x-1 text-secondary-40 dark:border-secondary-80 dark:text-secondary-80'}>
					<span className="capitalize">Clear</span>
					<XCircleIcon className="h-4 w-4 text-variant-30 dark:text-secondary-80"/>
				</button> : null}
			</div>
		</>
	);
}