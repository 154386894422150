import {axiosInstance, refreshAccessToken} from './axiosInstance';
import axios from 'axios';
import { authEndpoints } from 'services/api';


export default async function callAPI<R>(url: string, options?: object): Promise<R> {
	try {
		const response = await axiosInstance({ url, ...options});
		if (response.status >= 200 && response.status < 300) {
			return response.data;
		} else {
			throw new Error(`HTTP error: ${response.status}`);
		}
	} catch (error: any) {
		if (error.config.url === authEndpoints.login) {
			throw error;
		}
		if (axios.isAxiosError(error)) {
			if (error?.response?.status === 401) {

				await refreshAccessToken();

				try {
					const response = await axiosInstance({ url, ...options });
					if (response.status >= 200 && response.status < 300) {
						return response.data;
					} else {
						//throw new Error(`HTTP error: ${response.status}`);
					}
				} catch (error) {
					if (axios.isAxiosError(error)) {
						console.error('Axios error:', error.message);
					} else {
						console.error('Network error:', error);
					}
				}

			} else {
				console.error('Axios error:', error.message);
			}

			if (error.code === 'ECONNABORTED') {
				console.error('Request timed out.');
			}
		} else {
			console.error('Network error:', error);
		}
		throw error; // Rethrow the error for higher-level handling
	}
}
