import React, {useEffect, useState} from 'react';
import {ColumnType, sortType} from './Types';
import TableRow from './components/TableRow';
import SortToggle from './components/SortToggle';
import {useLazyLoad} from '../../hooks';

interface TableProps<T> {
	columns: ColumnType<T>[],
	headerClass: string,
	data: T[],
	className: string,
	options?: (entry: T) => React.ReactNode,
	onRowClick?: (entry: T) => void,
	currentSort?: sortType,
	initSort?: {
		by: {
			title: string,
			data_type: 'str' | 'num' | 'date' | 'bool' | 'range' | 'float' | 'int',
			key: string
		},
		direction: -1 | 1 | 0
	},
	itemsPerRender?: number
}

export default function Table<T>(props : TableProps<T>) {
	const {
		columns,
		headerClass,
		data,
		className,
		options,
		onRowClick,
		initSort = null,
		itemsPerRender
	} = props;

	const [sortedList, setSortedList] = useState([...data]);
	const [currentSort, setCurrentSort] = useState<sortType | null>(initSort);

	useEffect(() => {
		setSortedList([...data]);
	}, [data]);

	const {lazyData, targetRef} = useLazyLoad({
		data: sortedList,
		itemsPerRender: itemsPerRender || 24
	});

	useEffect(() => {
		if (!currentSort) return;
		const sortedData = [...data];
		if (currentSort.by?.data_type === 'date') {
			sortedData.sort((a , b) => {
				if (currentSort.direction < 0) return new Date(b[currentSort.by?.key as keyof T] as string).getTime() - new Date(a[currentSort.by?.key as keyof T] as string).getTime();
				if (currentSort.direction > 0) return new Date(a[currentSort.by?.key as keyof T] as string).getTime() - new Date(b[currentSort.by?.key as keyof T] as string).getTime();
				return 0;
			});
		}
		else if (currentSort.by?.key === 'Letter_Grade') {
			const grade = ['F', 'D-', 'D', 'D+', 'C-', 'C', 'C+', 'B-', 'B', 'B+', 'A-', 'A', 'A+'];
			sortedData.sort((a, b) => {
				const aGrade = grade.indexOf(a[currentSort.by?.key as keyof T] as string);
				const bGrade = grade.indexOf(b[currentSort.by?.key as keyof T] as string);
				if (currentSort.direction < 0) return bGrade - aGrade;
				if (currentSort.direction > 0) return aGrade - bGrade;
				return 0;
			});
		}
		else if (currentSort.by?.key === 'Grade' || currentSort.by?.key === 'Grade_Level') {
			const grade = ['KG', 'K', '01', '1', '02', '2', '03', '3', '04', '4', '05', '5', '06', '6', '07', '7', '08', '8', '09', '9', '10', '11', '12'];
			sortedData.sort((a, b) => {
				const aGrade = grade.indexOf(a[currentSort.by?.key as keyof T] + '');
				const bGrade = grade.indexOf(b[currentSort.by?.key as keyof T] + '');
				if (currentSort.direction < 0) return bGrade - aGrade;
				if (currentSort.direction > 0) return aGrade - bGrade;
				return 0;
			});
		}
		else if (currentSort.by?.data_type === 'int' || currentSort.by?.data_type === 'float' || currentSort.by?.data_type === 'range' || currentSort.by?.title === 'Risk Factor') {
			sortedData.sort((a, b) => {
				const aNum = Number.isNaN(Number(a[currentSort.by?.key as keyof T])) ? 0 : a[currentSort.by?.key as keyof T] as number;
				const bNum = Number.isNaN(Number(b[currentSort.by?.key as keyof T] as number)) ? 0 : b[currentSort.by?.key as keyof T] as number;
				return currentSort.direction > 0 ? aNum - bNum :  bNum - aNum;
			});
		}
		else if (currentSort.by?.data_type === 'str') {
			sortedData.sort((a, b) => {
				const aValue = a[currentSort.by?.key as keyof T];
				const bValue = b[currentSort.by?.key as keyof T];

				// Handle null or undefined for aValue
				if (aValue === null || aValue === undefined) {
					return (bValue === null || bValue === undefined) ? 0 : (currentSort.direction < 0 ? 1 : -1);
				}

				// Handle null or undefined for bValue
				if (bValue === null || bValue === undefined) {
					return (currentSort.direction < 0 ? -1 : 1);
				}

				// Both values are not null/undefined, proceed with normal comparison
				const aStr = (aValue as string).toLowerCase();
				const bStr = (bValue as string).toLowerCase();
				return currentSort.direction < 0 ? bStr.localeCompare(aStr) : aStr.localeCompare(bStr);
			});
		}
		else if (currentSort.by?.data_type === 'bool') {
			sortedData.sort((a, b) => {
				if (currentSort.direction < 0) return Number(b[currentSort.by?.key as keyof T] as boolean) - Number(a[currentSort.by?.key as keyof T] as boolean);
				if (currentSort.direction > 0) return Number(a[currentSort.by?.key as keyof T] as boolean) - Number(b[currentSort.by?.key as keyof T] as boolean);
				return 0;
			});
		}
		setSortedList(sortedData);
	}, [currentSort, data]);

	return (
		<div>
			<table
				className={' w-full overflow-auto dark:text-lightMode-primary ' + className}
			>
				<thead className={'text-left sticky ' + headerClass}>
					<tr>
						{
							columns.map((col : ColumnType<T> , i : number) => {
								return (
									<th key={col.title} className={` font-medium py-2 text-sm px-2 cursor-pointer  transition duration-200 ${i === 0 ? 'rounded-tl' : ''} ${i === columns.length - 1 && !options ? 'rounded-tr' : ''}`} onClick={() => {
										let direction: -1 | 1 | 0 = 0;
										if (currentSort?.by?.title !== col.title) direction = 1;
										else if (currentSort.direction === 0) direction = 1;
										else if (currentSort.direction === 1) direction = -1;
										setCurrentSort({
											by: direction === 0 ? null : { title: col.title as string, data_type: col.data_type, key: col.key as string},
											direction: direction
										});
									}}>
										<div className={'flex gap-2 justify-start items-center'}>
											<div>{col.title}</div>
											<div>
												{
													currentSort && <SortToggle className={' text-lightMode-primary '} column={col} currentSort={currentSort}/>
												}
											</div>
										</div>
									</th>
								);
							})
						}
						{options && <th className={'text-left rounded-tr'}></th>}
					</tr>
				</thead>
				<tbody className={'bg-neutral-95'}>
					{lazyData.map((entry : T, i : number) => {
						return <TableRow entry={entry} columns={columns} key={i} options={options} onRowClick={onRowClick}/>;
					})}
					{data.length > lazyData.length &&
						<tr className="py-10 w-full h-2">
							<td>
								<div className={'h-full'} ref={targetRef}></div>
							</td>
						</tr>}
				</tbody>
			</table>
		</div>
	);
}