// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, {useState} from 'react';
import ReactSlider from 'react-slider';
import { Filters, FilterValue, FilterOptions } from '@modules/common/types';

interface RangeInputProps {
  filter: FilterValue
  filterKey: keyof Filters;
	getFilterOptions: (filterKey: keyof Filters) => FilterOptions;
  range: [number, number];
  onClick: (filterKey: keyof Filters, newValue: [number, number]) => void;
}

export default function RangeInput({ filter, filterKey, getFilterOptions, range, onClick }: RangeInputProps) {
	const [value, setValue] = useState(filter as [number, number]);

	const [minValue, maxValue] = range;

	const minDistance = 1;

	const handleInputChange = (e: Event, index: number) => {
		const newValue: [number, number] = value.map((v, i): [number, number] => {
			if (i === index) {
				if (e.target.value > maxValue - minDistance) return maxValue - minDistance;
				else if (e.target.value < minValue + minDistance) return minValue + minDistance;
				else return Number(e.target.value);
			}
			else return v;
		});

		onClick(filterKey, newValue);
		setValue(newValue);
	};

	return (
		<>
			<h4 className="capitalize text-variant-50 ">{(filterKey as string).replace('_', ' ')}</h4>
			<div className="p-4">
				<ReactSlider
					className="align-middle w-56 items-center content-center justify-center h-2 flex pb-8 mt-2"
					defaultValue={(filter as [number, number])[0]}
					value={value}
					min={minValue}
					max={maxValue}
					onChange={(value) => setValue(value)}
					onAfterChange={(value) => onClick(filterKey, value)}
					ariaLabel={'Lower thumb'}
					ariaValuetext={state => `Thumb value ${state.valueNow}`}
					renderThumb={(props, state) => <input {...props} className={`w-7 h-8 text-xs text-center rounded-xl border border-neutral-70 content-center justify-center bg-neutral-90 items-center align-middle ${state.index === 0 ? '-ml-3' : 'ml-3'}`} value={state.valueNow} onChange={(e) => handleInputChange(e, state.index)}/>}
					pearling
					disabled
					minDistance={minDistance}/>
				<ReactSlider
					className="align-middle w-56 items-center content-center justify-center h-2 flex"
					defaultValue={(filter as [number, number])[1]}
					min={minValue}
					value={value}
					max={maxValue}
					onChange={(value) => setValue(value)}
					onAfterChange={(value) => onClick(filterKey, value)}
					ariaLabel={'Upper thumb'}
					ariaValuetext={state => `Thumb value ${state.valueNow}`}
					renderThumb={(props, state) => <span {...props} className={`bg-neutral-90 w-7 h-7 border-2 border-primary-40 rounded-full content-center justify-center items-center align-middle ${state.index === 0 ? '-ml-3' : 'ml-3'}`}></span>}
					renderTrack={(props) => <span {...props} className={`${props!.key === 'track-1' ? 'bg-primary-40 ' : 'bg-neutral-90'} ${props!.key === 'track-2' ? 'ml-3' : ''} h-2 rounded-full`}></span>}
					pearling
					minDistance={minDistance}
				/>
			</div>
		</>
	);
}