import React from 'react';
import RangeCalendar from '@modules/common/components/RangeCalendar';
// import { useLocale } from '@react-aria/i18n';

// const options = {
// 	year: 'numeric',
// 	month: 'short',
// 	day: 'numeric',
// };
interface DateFilter {
  start: string;
  end: string;
}
type FilterValue = Set<string | number> | [number, number] | string | DateFilter;

type FilterOptions = Set<string | number>;


interface Filters {
  [key: string]: FilterValue;
}
interface RangeCalendarInputProps {
  range: { start: any, end: any }
  filterKey: keyof Filters;
  onClick: (filterKey: keyof Filters, range: any) => void;
}

export default function RangeCalendarInput({ range, filterKey, onClick }: RangeCalendarInputProps) {
	// const locale = useLocale();

	const handleRangeUpdate = (range: any) => {
		onClick(filterKey, { ...range });
	};

	// const rangeToString = () => {
	// 	function dateToString(date) {
	// 		return new Intl.DateTimeFormat(locale, options).format(date.toDate(Intl.DateTimeFormat().resolvedOptions().timeZone));
	// 	}
	// 	// eslint-disable-next-line react/prop-types
	// 	const start = range.start ? dateToString(range.start) : '';
	// 	// eslint-disable-next-line react/prop-types
	// 	const end = range.end ? dateToString(range.end) : '';
	// 	return `${start !== end ? `${start} - ${end}` : start}`;
	// };

	return (
		<div>
			<RangeCalendar
				defaultValue={range.start && range.end ? {...range} : null}
				updateRange={handleRangeUpdate}
			/>
		</div>
	);
}