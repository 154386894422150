import { configureStore } from '@reduxjs/toolkit';
import { pinnedCardReducer, searchReducer, performReducer, hudReducer, magnifyReducer, HudState, MagnifyState, PerformState, PinnedCardState, SearchState } from 'store/slices';

const store =  configureStore({
	reducer: {
		pinnedCard: pinnedCardReducer,
		search: searchReducer,
		perform: performReducer,
		hud: hudReducer,
		magnify: magnifyReducer,
	}
});

export interface RootState {
	pinnedCard: PinnedCardState,
	search: SearchState,
	perform: PerformState,
	hud: HudState,
	magnify: MagnifyState,
}

export default store;

export type { HudState, MagnifyState, PerformState, PinnedCardState, SearchState };