import { magnifyEndpoints } from 'services/api';

import callAPI from 'services';
import {DashboardResponseType, DashboardCardType} from './types/DashboardTypes';

const fetchDashboardList = async <T>(): Promise<DashboardResponseType<T>> => {
	return await callAPI<DashboardResponseType<T>>(magnifyEndpoints.dashboardList, {});
};

const fetchRecentList = async <T>(): Promise<DashboardResponseType<T>> => {
	return await callAPI<DashboardResponseType<T>>(magnifyEndpoints.recentDashboards, {});
};

const fetchDashboard = async <T>(dashboardID: string): Promise<DashboardResponseType<T>> => {
	return await callAPI<DashboardResponseType<T>>(`${magnifyEndpoints.dashboard}${dashboardID}`, {});
};

const fetchDashboardCard = async (cardURL: string): Promise<DashboardCardType> => {
	return await callAPI<DashboardCardType>(`${magnifyEndpoints.dashboardCard}${cardURL}`, {});
};

export { fetchDashboardList, fetchRecentList, fetchDashboard, fetchDashboardCard };