export function sortStringWithNumbersLast(a: string | number, b: string | number) {
	const aIsNumber = !isNaN(Number(a));
	const bIsNumber = !isNaN(Number(b));

	if (aIsNumber && bIsNumber) {
		return Number(a) - Number(b);
	}

	if (aIsNumber) {
		return 1; // Return 1 to place numbers at the end
	}

	if (bIsNumber) {
		return -1; // Return -1 to place numbers at the end
	}

	return (a as string).localeCompare(b as string);
}