import React, {lazy, Suspense} from 'react';
import {NavigationItem, User} from '@types';
import { Sidebar, PinnedCards, Topbar } from 'components';
import { useQuery } from '@tanstack/react-query';
import { ChartBarIcon, DocumentTextIcon, UserGroupIcon} from '@icons';
import { CreoviaLoad } from '@modules/common/components';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { authenticate } from 'services/api';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppLayout } from '@modules/common/layouts';
import { Login, ForgotPassword } from '@modules/auth';
import ErrorFallback from './@modules/errors/ErrorFallback';
import Fake from 'Fake';

{ /* Code-Split */}
const Directory = lazy(() => import('@modules/directory'));
const Home = lazy(() => import('@modules/home'));
const Observation = lazy(() => import('@modules/perform/pages/Observation'));
const ObservationsDashboard = lazy(() => import('@modules/perform/pages/ObservationsDashboard'));
// const ObservationPDFOld = lazy(() => import('@modules/perform/pages/ObservationPDFOld'));
const Magnify = lazy(() => import('@modules/magnify/Magnify'));
const MagnifyHome = lazy(() => import('@modules/magnify/Components/MagnifyHome'));

function App() {
	const {data: user, isLoading} = useQuery<User>({
		queryKey: ['user'],
		queryFn: authenticate,
		refetchOnWindowFocus: false,
		retry: false,
	});

	const navigationMap = {
		'connect': UserGroupIcon,
		'magnify': ChartBarIcon,
		'perform': DocumentTextIcon,
		// 'pass': Squares2X2Icon
	};

	const navigationItems: NavigationItem[] = user?.viewableApps ? Object.keys(user.viewableApps).map(appKey => (
		{
			name: user.viewableApps[appKey],
			icon: navigationMap[appKey as keyof typeof navigationMap],
			path: `/${appKey}`
		}
	)) : [];

	// navigationItems.push({
	// 	name: 'Pass',
	// 	icon: Squares2X2Icon,
	// 	path: '/pass'
	// });

	const location = useLocation();
	const outlet = location.state?.outlet || '/connect';

	{/* Authenticating... */}
	if (isLoading) return <CreoviaLoad />;

	return (
		<ErrorBoundary fallback={<ErrorFallback />}>
			{user && !user.force_password_change ? ( // Protected Routes
				<div className="app-container flex bg-neutral-95 text-neutral-5">
					<Sidebar navigationItems={navigationItems} />
					<div id="middle-column" className="middle-column overflow-y-auto h-screen w-full relative">
						<Suspense fallback={
							<div className="flex w-full flex-col gap-10 mx-auto lg:px-10" style={{maxWidth: '1920px'}}>
								<Topbar />
								<AppLayout><div/></AppLayout>
							</div>
						}>
							<main className="flex flex-col gap-10 mx-auto lg:px-10" style={{maxWidth: '1920px'}}>

								<Topbar />
								<Routes>

									<Route path="/" element={<Navigate to="home" />} />
									<Route path="/login" element={<Navigate to={outlet} />} />

									<Route path="home" element={<Home navigationItems={navigationItems}/>} />

									{/* Our Apps */}
									<Route path="/connect" element={<Directory />} />
									<Route path="/perform" element={<ObservationsDashboard />} />
									<Route path="/perform/observation" element={<Observation />} />
									{/*<Route path="/perform/observation-pdf" element={<ObservationPDFOld />} />*/}
									<Route path="/magnify/dashboard/:titleWithID" element={<Magnify />} />
									<Route path='/magnify' element={<MagnifyHome />} />
									<Route path='/error' element={<Fake />} />

									{/*<Route path='/announce' element={<Announce/>} />*/}
									<Route path="/*" element={<ErrorFallback />} />
								</Routes>
							
							</main>
						</Suspense>
					</div>

					<PinnedCards />
				</div>
			) : ( // Public Routes
				<Routes>
					<Route path="/login" element={<Login />} />
					<Route path="/reset" element={user ? <Navigate to="/" /> : <ForgotPassword />} /> {/* Weird logic, but it makes no assumptions on how things are supposed to work (removes this route on force password change) */}
					<Route path="/*" element={<Navigate to="/login" state={{ outlet: location.pathname }} />} />
				</Routes>
			)}

			<ReactQueryDevtools initialIsOpen={false} />

		</ErrorBoundary>
	);
}

export default App;
