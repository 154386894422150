/**
 * Endpoints used to make API calls
 *
 * There are currently have two BASE_URLs:
 * Production API and Developer API, imported from environment variables.
 *
 */

const { REACT_APP_PROD_BASE_URL: PROD_BASE_URL, REACT_APP_DEV_BASE_URL: DEV_BASE_URL } = process.env;

// Endpoints used for authentication
export const authEndpoints = {
	refresh: `${PROD_BASE_URL}api/auth/refresh/`,
	authenticate: `${PROD_BASE_URL}api/user/`,
	login: `${PROD_BASE_URL}api/auth/login/`,
	updatePassword: `${PROD_BASE_URL}api/auth/update_password/`,
};

// Endpoints used within the directory app
export const directoryEndpoints = {
	directoryList: `${PROD_BASE_URL}api/directory/userList/`,
};

// Endpoints used within perform
export const performEndpoints = {
	observation: `${PROD_BASE_URL}api/perform/observation/`,
	publishObservation: `${PROD_BASE_URL}api/perform/publishObservation/`,
	unpublishObservation: `${PROD_BASE_URL}api/perform/unpublishObservation/`,
	observationList: `${PROD_BASE_URL}api/perform/observationList/`,
	deleteObservation: `${PROD_BASE_URL}api/perform/deleteObservation/`,
	observableProfiles: `${PROD_BASE_URL}api/perform/observableProfiles/`,
	availableTemplates: `${PROD_BASE_URL}api/perform/availableTemplates/`,
	newObservation: `${PROD_BASE_URL}api/perform/newObservation/`,
};

// Endpoints used within magnify
export const magnifyEndpoints = {
	// dashboardCard endpoint is not being used because the endpoint comes from the concatenation of the card slider property on the DashboardItem and the ID of the student
	dashboardList: `${PROD_BASE_URL}api/magnify/dashboards/`,
	dashboard: `${PROD_BASE_URL}api/magnify/dashboard/`,
	dashboardCard: `${PROD_BASE_URL}api/cards/studentProfile/`,
	recentDashboards: `${PROD_BASE_URL}api/magnify/recentDashboards/`,
};

// Endpoints used within announce
export const announceEndpoints = {
	announceList: `${DEV_BASE_URL}api/v1/announce/all/`,
	newAnnounce: `${DEV_BASE_URL}api/v1/announce/new/`,
	announcePost: `${DEV_BASE_URL}api/v1/announce/posts/<int:pk>`,
	recentList: `${DEV_BASE_URL}api/v1/announce/recent/`
};