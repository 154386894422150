import React from 'react';

const EvaluationPinnedCard = ({data}: {data: any}) => {

	const getColor = (key : string) => {
		switch (key) {
		case 'Substantial Growth Opportunity':
			return 'bg-rubric-substantial';
		case 'Significant Growth Opportunity':
			return 'bg-rubric-significant';
		case 'Moderate Growth Opportunity':
			return 'bg-rubric-moderate';
		case 'Minor Growth Opportunity; Fluent':
			return 'bg-rubric-minor';
		}
	};


	return (
		<>
			<h1 className={'mx-5 text-xl mt-6 font-semibold pr-10'}>{data.title}</h1>
			{data.rubric.map((rubricItem: any, i: number) => {
				return (
					<div key={i} className={'bg-neutral-80 border border-neutral-70 p-2 mx-5 rounded-md text-sm'}>
						<div className={'flex justify-between'}>
							<div className={'text-md font-semibold'}>{rubricItem.banner}</div>
							<div className={`h-6 rounded-full aspect-square ${getColor(rubricItem.banner)}`}></div>
						</div>
						<div>{Object.keys(rubricItem.blocks).map((key : string) => {
							return (
								<div key={key}>
									<div className={'font-semibold'}>{key.split('')[0].toUpperCase() + key.slice(1)}</div>
									<div>{rubricItem.blocks[key]}</div>
								</div>
							);
						})}
						</div>
					</div>
				);
			})}
		</>
	);
};

export default EvaluationPinnedCard;
